import {
	Box,
	Button,
	Divider,
	Grid,
	List,
	Skeleton,
	Stack,
	Typography,
} from "@mui/material";

import { FeaturedCurationCard } from "../components/CurationCards/FeaturedCurationCard";
import { CategoryList } from "../components/ProductCategory/CategoryList";
import { BottomNavbar } from "../layout/footer";
import { Header } from "../layout/header";
import { Link } from "@mui/material";
import { ArrowForwardIos } from "@mui/icons-material";
import localforage from "localforage";
import { useContext, useEffect, useState } from "react";

import { useRouter } from "next/router";
import {
	getCurations,
	getDetails,
	getLogedInUserDetails,
} from "../service/home";
import GeneralHead from "../components/Head/GeneralHead";
import { PlayOffCard } from "../components/Home/PlayOffCard";
// import Tour from 'reactour'
import dynamic from "next/dynamic";
import { PlayoffList } from "../components/ProductCategory/PlayoffList";
import { AuthDrawer } from "../components/auth/Index";

import { CreateListFavourite, UpdateListFavourite } from "../service/Curation";
import { useInView } from "react-intersection-observer";

import { ScrollContainer } from "../components/common/ScorllContainer";
import {
	CreateProductFavourite,
	CreateProductView,
	UpdateProductFavourite,
	UpdateProductView,
} from "../service/products";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import { getAuth } from "firebase/auth";
import HomeGrapeOfficailCard from "../components/HomeGrapeOfficialCard/HomeGrapeOfficailCard";
import { getAuthToken } from "../utility/checkUser";
import { useSession } from "next-auth/react";
import useSWR from "swr";
import { useRouterScroll } from "@moxy/next-router-scroll";
import { ScrollRestorationContext } from "../context/ScrollRestorationContext";
import GrapeListSkeleton from "../components/Skeletons/GapeList/GrapeListSkeleton";
import ActivePlayoffSkeleton from "../components/Skeletons/ActivePlayoffSkeleton/ActivePlayoffSkeleton";
import CurationCardSkeleton from "../components/Skeletons/CurationCardSkeleton/CurationCardSkeleton";
import HomeScreenSkeleton from "../components/Skeletons/Screens/HomeScreenSkeleton";
import ProductCardSkeleton from "../components/Skeletons/ProductCardSkeleton/ProductCardSkeleton";

import CompanyNameFooter from "../layout/CompanyNameFooter";
import { ProductCard } from "../components/Product/ProductCard";
import { CurationDrawer } from "../components/Product/CurationDrawer";
import { ScrollContext } from "../context/ScrollContext";
const Tour = dynamic(() => import("reactour"), { ssr: false });

const Home = ({ token }) => {
	const { data: session, status } = useSession();

	const fetcher = async (url) =>
		await getDetails(token).then((res) => res.data);
	const { data, error, isLoading } = useSWR("homepage_index", fetcher);
	// const { active_playoff, featured_curations: featuredCurationData = [] } =
	// 	data;
	const [active_playoff, setActive_playoff] = useState();
	const router = useRouter();

	const [showLoadingScreen, setShowLoadingScreen] = useState(true);
	const [showProductTour, setShowProductTour] = useState(false);
	const [curations, setCurations] = useState([]);

	const [featured_curations, setFeatured_curations] = useState([]);
	const [categories, setCategories] = useState([]);
	const [curated_playoffs, setCurated_playoffs] = useState([]);
	const [playoffs, setPlayoffs] = useState([]);
	const [grapeList, setGrapeList] = useState([]);
	const [authOpen, setAuthOpen] = useState(false);
	const [persistentList, setPersistentList] = useState([]);
	const [products, setProducts] = useState([]);
	const [animationSeen, setAnimationSeen] = useState(false);
	const [open, setOpen] = useState(false);
	const [curationsCount, setCurationsCount] = useState(null);

	const { ref, inView } = useInView({
		threshold: 0,
	});

	const [isAuthenticated, setIsAuthenticated] = useState(false);
	const { updateScroll } = useRouterScroll();
	// useEffect(() => {
	// 	setIsUpdated(true)
	// }, [status]);

	// const {scrollContainerRef} = scrollContextApi

	const { pageDetail, scrollContainerRef, setPageDetail } =
		useContext(ScrollContext);

	useEffect(() => {
		const func = async () => {
			if (status !== "loading") {
				setIsAuthenticated(status === "authenticated");

				if (pageDetail) {
					if (pageDetail.isTrayOpen) {
						console.log(pageDetail.traydetail.curationCount);
						setCurationsCount(pageDetail.traydetail.curationCount);
					}
					window.scrollTo({
						top: pageDetail.scrollTo,
						behavior: "auto",
					});
				}

				let res = await getLogedInUserDetails(session);
				const {
					categories,
					curated_playoffs,
					featured_curations,
					playoffs,
					grapeList,
					persistentList,
				} = res.data;
				setCategories(categories);
				setCurated_playoffs(curated_playoffs);
				setFeatured_curations(featured_curations);
				setPlayoffs(playoffs);
				setGrapeList(grapeList);
				setPersistentList(persistentList);
				setProducts(persistentList?.products);
				setShowLoadingScreen(false);
				// if (pageDetail && scrollContainerRef.current) {
				// 	scrollContainerRef.current.scroll({
				// 		top: pageDetail.scrollTo,
				// 		behavior: "auto",
				// 	});
				// }
			}
		};
		func();
	}, [session, scrollContainerRef, isLoading]);

	const closeProductTour = () => {
		setShowProductTour(false);
	};

	const checkSelectedCategories = async () => {
		let categories_exist = await localforage.getItem("set_categories");
		categories_exist = JSON.parse(categories_exist);
		if (!categories_exist) {
			router.push("/welcome");
		} else {
			setShowSplashScreen(false);
		}
	};
	// const getLoadDetails = async () => {
	// 	const { data } = await getDetails();
	// 	setPlayoffs(data.playoffs);
	// };
	const updateProducts = (product) => {
		let newProducts = [...products];
		let index = newProducts.findIndex((p) => product.id === p.id);
		newProducts[index] = product;
		setProducts(newProducts);
	};

	const handleProductFavorite = async (product) => {
		if (isAuthenticated) {
			let { product_favourite } = product;
			let user = session.user;
			if (product_favourite.length) {
				let pf = product_favourite[0];
				pf.favourite = false;
				let res = await UpdateProductFavourite(pf);

				product.product_favourite = product.product_favourite.filter(
					(product_favourite) => pf.id !== product_favourite.id
				);
				updateProducts(product);
			} else {
				let res = await CreateProductFavourite({
					product: product.id,
					faved_by: user.id,
					m_list: grapeList.id,
				});

				product.product_favourite = [res.data];
				updateProducts(product);
			}
		} else {
			setAuthOpen(true);
		}
	};

	const handleProductViews = async (product) => {
		let { product_views } = product;
		window.open(
			product.affiliated_url ? product.affiliated_url : product.url,
			"_blank",
			"noreferrer"
		);
		if (product_views.length) {
			let product_view = product_views[0];
			product_view.views += 1;
			let res = await UpdateProductView(product_view);
			product.product_views[0] = res.data;
			// updateProducts(product);
		} else {
			let input_data = {
				product: product.id,
				// m_list: curation.id,
			};
			if (isAuthenticated) {
				let user = session.user;
				input_data.viewed_by = user.id;
			} else {
				let currentUser = getAuth().currentUser;
				input_data.uid = currentUser?.uid;
			}
			let res = await CreateProductView(input_data);
			product.product_views = [res.data];
			// updateProducts(product);
			// window.open(product.url, "_blank", "noreferrer");
		}
	};
	const updateAnimationState = () => {
		setAnimationSeen(!animationSeen);
	};
	useEffect(() => {
		if (data !== undefined) {
			const { featured_curations, active_playoff } = data;

			setActive_playoff(active_playoff);
			// 	setFeatured_curations([]);
			// setCategories(categories);
			// setCurated_playoffs(curated_playoffs);
			setFeatured_curations(featured_curations);
			// setPlayoffs(playoffs);
			// setGrapeList(grapeList);
			// setPersistentList(persistentList);
			// setProducts(persistentList?.products);
			setShowLoadingScreen(false);
		}
	}, [data]);

	useEffect(() => {
		// getLoadDetails();
		let isFirstLoad = JSON.parse(localStorage.getItem("isFirstLoad"));

		if (isFirstLoad == false) {
			setShowProductTour(false);
		} else {
			localStorage.setItem("isFirstLoad", false);
			setShowProductTour(false);
		}
		setTimeout(() => {
			// checkSelectedCategories();
		}, 3000);
	}, []);

	const steps = [
		{
			selector: ".grapeLogo",
			style: {
				backgroundColor: "#9650FA",
				padding: 16,
			},
			content: () => (
				<Typography variant="body2" sx={{ color: "white", pt: "4px" }}>
					Share, discover & shop fashion on Grape 💜
				</Typography>
			),
		},
		{
			selector: ".mainplayoffCard",
			style: {
				backgroundColor: "#9650FA",
				padding: 16,
			},
			content: () => (
				<Typography variant="body2" sx={{ color: "white", pt: "4px" }}>
					Start by curating here
				</Typography>
			),
		},
		// {
		//   selector: '.discover',
		//   style:{
		// 	backgroundColor:"#9650FA",
		// 	padding:16
		//   },
		//   content: ()=>(<Typography variant="body2" sx={{color:"white",pt:"4px"}} >Discover curations by others here</Typography>)
		// },
		{
			selector: ".playoff",
			style: {
				backgroundColor: "#9650FA",
				padding: 16,
			},
			content: () => (
				<Typography variant="body2" sx={{ color: "white", pt: "4px" }}>
					Curate more here
				</Typography>
			),
		},
		{
			selector: ".playoffCard0",
			style: {
				backgroundColor: "#9650FA",
				padding: 16,
			},
			content: () => (
				<Typography
					variant="body2"
					sx={{ color: "white", pt: "4px" }}
					className="actual-content"
				>
					Tap to browse & shop
				</Typography>
			),
		},
	];

	// Handle Curation Code  Start

	const handleListFavorite = async (curationId) => {
		// let user = await getUser();

		if (status === "authenticated") {
			let user = session.user;
			let curation = featured_curations.find(
				(item) => item.id === curationId
			);
			if (curation?.user_list_favourites) {
				let obj = {
					id: curation.user_list_favourites.id,
					favourite: curation.user_list_favourites.favourite
						? false
						: true,
					faved_by: curation.user_list_favourites.faved_by,
				};
				let res = await UpdateListFavourite(obj, session);
				curation.user_list_favourites = null;
				curation.favourites_count -= 1;
			} else {
				let res = await CreateListFavourite(
					{
						faved_by: user.id,
						m_list: curationId,
					},
					session
				);
				curation.favourites_count += 1;
				curation.user_list_favourites = res.data;
			}

			let index = featured_curations.findIndex(
				(item) => item.id == curationId
			);

			let new_curationsList = featured_curations.filter(
				(curation) => curation.id !== curationId
			);
			new_curationsList.splice(index, 0, curation);
			setFeatured_curations(new_curationsList);
		} else {
			setAuthOpen(true);
		}
		console.log("executed");
	};
	const handleCurationListFavorite = async (curationId) => {
		if (status === "authenticated") {
			let user = session.user;
			let curation = curations.find((item) => item.id === curationId);
			if (curation?.user_list_favourites) {
				let obj = {
					id: curation.user_list_favourites.id,
					favourite: curation.user_list_favourites.favourite
						? false
						: true,
					faved_by: curation.user_list_favourites.faved_by,
				};
				let res = await UpdateListFavourite(obj, session);
				curation.user_list_favourites = null;
				curation.favourites_count -= 1;
			} else {
				let res = await CreateListFavourite(
					{
						faved_by: user.id,
						m_list: curationId,
					},
					session
				);
				console.log(curation);
				curation.favourites_count += 1;
				curation.user_list_favourites = res.data;
			}

			let index = curations.findIndex((item) => item.id == curationId);

			let new_curationsList = curations.filter(
				(curation) => curation.id !== curationId
			);
			new_curationsList.splice(index, 0, curation);

			console.log(new_curationsList);
			setCurations(new_curationsList);
		} else {
			setAuthOpen(true);
		}
		console.log("executed");
	};
	const handleGrapeListFavorite = async (curationId) => {
		if (status === "authenticated") {
			let user = session.user;
			let curation = grapeList.find((item) => item.id === curationId);
			console.log(curation);
			if (curation.user_list_favourites) {
				let obj = {
					id: curation.user_list_favourites.id,
					favourite: curation.user_list_favourites.favourite
						? false
						: true,
					faved_by: curation.user_list_favourites.faved_by,
				};
				let res = await UpdateListFavourite(obj, session);
				curation.user_list_favourites = null;
				curation.favourites_count -= 1;
			} else {
				let res = await CreateListFavourite(
					{
						faved_by: user.id,
						m_list: curationId,
					},
					session
				);

				curation.favourites_count += 1;
				curation.user_list_favourites = res.data;
			}

			let index = grapeList.findIndex((item) => item.id == curationId);

			let new_curationsList = grapeList.filter(
				(curation) => curation.id !== curationId
			);
			new_curationsList.splice(index, 0, curation);

			console.log(new_curationsList);
			setGrapeList(new_curationsList);
		} else {
			setAuthOpen(true);
		}
	};

	// Handle Curation Code  end

	return (
		<>
			<GeneralHead
				description={""}
				ogUrl={"www.grape.town"}
				ogImage={
					"https://d2h50zujfkj84t.cloudfront.net/Grape1_91_1.jpg"
				}
				ogTitle={"Grape | Your Shortcut to Fashion"}
			/>

			{isLoading ? (
				<HomeScreenSkeleton />
			) : (
				<Box>
					<Box pb={8} sx={{ backgroundColor: "background2.main" }}>
						<Header />
						<Link href="https://grape.onelink.me/dwuE/ker2b503s" target="_blank" rel="noopener noreferrer" sx={{
							textDecoration:"none", color:"rgba(0, 0, 0, 0.87)"
						}}>
						<Box sx={{width:"100%", height:40, background: "linear-gradient(270deg, #DDC4F5 2.94%, #CDF1F5 100%)", display:"flex", alignItems:"center", px:2, justifyContent:"space-between"}} >

							<Typography variant="body2" sc>Grape now on Android Tap to download</Typography>
							<ArrowForwardIos
											sx={{fontSize:14}}
											
										/>
						</Box>
						</Link>
						<Box
							sx={{
								marginTop: 2.5,
								marginBottom: 2.75,
								marginX: 2.5,
							}}
						>
							{active_playoff ? (
								<Box className="mainplayoffCard">
									<PlayOffCard
										class
										playoff={active_playoff}
									/>
								</Box>
							) : (
								<ActivePlayoffSkeleton
									firstCard={true}
								></ActivePlayoffSkeleton>
							)}
						</Box>
						<Box
							sx={{
								marginY: 3.128,
								marginX: 2.5,
							}}
						>
							<Typography
								variant="h2"
								sx={{
									textAlign: "center",
									textTransform: "capitalize",
								}}
								mb={1}
							>
								Featured Community Curations
							</Typography>

							<Typography
								variant="body2"
								sx={{
									fontWeight: 200,
									textAlign: "center",
									// textTransform: "capitalize",
								}}
								mx={2}
								mb={3}
							>
								Browse & shop curations by others like you
							</Typography>
							{featured_curations.length
								? featured_curations.map((curation, i) => (
										<FeaturedCurationCard
											type="featured"
											handleListFavorite={
												handleListFavorite
											}
											home={true}
											key={`playoff-${i}`}
											curation={curation}
											scrollContainerRef={
												scrollContainerRef
											}
										/>
								  ))
								: Array.from(new Array(3)).map(
										(item, index) => {
											return (
												<Box
													key={
														"curationSkeleton" +
														index
													}
													sx={{
														marginRight: 0.5,
														my: 5,
													}}
												>
													<CurationCardSkeleton></CurationCardSkeleton>
												</Box>
											);
										}
								  )}
						</Box>

						{persistentList && (
							<>
								<Box py={3.128}>
									<Box sx={{ marginX: "auto" }} mb={2}>
										<AnimationOnScroll
											initiallyVisible={true}
											animateIn="animate__zoomIn"
											afterAnimatedOut={() =>
												setAfterOutState((c) => c + 1)
											}
										>
											<Typography
												component="div"
												variant="h2"
												sx={{
													textAlign: "center",
													textTransform: "capitalize",
													mb: 1,
												}}
											>
												{persistentList.title}
											</Typography>
										</AnimationOnScroll>
										<Typography
											variant="body2"
											sx={{
												fontWeight: 200,
												textAlign: "center",
												// textTransform: "capitalize",
											}}
											mx={2}
											mb={3}
										>
											Most popular products updated daily
										</Typography>
									</Box>

									<Grid container spacing={2} pt={2} px={2}>
										{persistentList.products
											? persistentList.products
													?.slice(0, 4)
													.map((product, i) => (
														<ProductCard
															curation={
																persistentList
															}
															key={"prod-" + i}
															product={product}
															handleViews={
																handleProductViews
															}
															session={session}
															status={status}
															setAuthOpen={
																setAuthOpen
															}
															setOpen={setOpen}
															setCurationsCount={
																setCurationsCount
															}
														/>
														// <CurationDetailsProductCard
														// 	key={"prod-" + i}
														// 	product={product}
														// 	handleFavorite={
														// 		handleProductFavorite
														// 	}
														// 	handleViews={
														// 		handleProductViews
														// 	}
														// 	// isAuthenticated={isAuthenticated}
														// 	// setAuthOpen={setAuthOpen}
														// ></CurationDetailsProductCard>
													))
											: Array.from(new Array(2)).map(
													(item, index) => {
														return (
															<Grid
																item
																xs={6}
																key={
																	"PersitentList" +
																	index
																}
															>
																<ProductCardSkeleton></ProductCardSkeleton>
															</Grid>
														);
													}
											  )}
									</Grid>
								</Box>
								<Box
									sx={{
										typography: "altag",
										display: "flex",
										alignItems: "center",
										flexWrap: "wrap",
										justifyContent: "center",
									}}
								>
									<Button
										variant="text"
										color="primary"
										href={`/list/${persistentList.slug}`}
										sx={{
											fontSize: "14px",
											lineHeight: "18px",
											textTransform: "initial",
										}}
									>
										See More{" "}
										<ArrowForwardIos
											fontSize="inherit"
											// sx={{ marginRight: 1 }}
										/>
									</Button>
								</Box>
							</>
						)}
						<Box
							sx={{
								marginY: 3.128,
								marginX: 2.5,
							}}
						>
							<Typography
								variant="h2"
								sx={{
									textAlign: "center",
									textTransform: "capitalize",
								}}
								mb={1}
							>
								Browse Inhouse Curations
							</Typography>

							<Typography
								variant="body2"
								sx={{
									fontWeight: 200,
									textAlign: "center",
									// textTransform: "capitalize",
								}}
								mx={2}
								mb={3}
							>
								Shopping guides by our team filled with on-trend
								products
							</Typography>
							{/* {grapeList.map((curation, i) => (
						<FeaturedCurationCard
							handleListFavorite={handleGrapeListFavorite}
							key={`playoff-${i}`}
							curation={curation}
						/>
					))} */}
							{grapeList.length > 0
								? grapeList.map((curation, i) => (
										<HomeGrapeOfficailCard
											handleListFavorite={
												handleGrapeListFavorite
											}
											key={`playoff-${i}`}
											curation={curation}
										/>
								  ))
								: Array.from(new Array(3)).map(
										(item, index) => {
											return (
												<Box
													key={"knfg" + index}
													sx={{
														marginRight: 0.5,
														my: 5,
													}}
												>
													<GrapeListSkeleton></GrapeListSkeleton>
												</Box>
											);
										}
								  )}
							<Box
								sx={{
									typography: "altag",
									display: "flex",
									alignItems: "center",
									flexWrap: "wrap",
									justifyContent: "center",
								}}
							>
								<Typography
									variant="atag"
									underline="none"
									component={Link}
									href="/list"
									direction="row"
									alignItems="center"
									color="primary"
								>
									See More{" "}
									<ArrowForwardIos
										fontSize="inherit"
										// sx={{ marginRight: 1 }}
									/>
								</Typography>
							</Box>
						</Box>
						<Box
							sx={{
								marginY: 3.128,
								marginX: 2.5,
							}}
						>
							<Typography
								variant="h2"
								sx={{
									textAlign: "center",
									textTransform: "capitalize",
								}}
								mb={1}
							>
								Challenge Yourself
							</Typography>
							<Typography
								variant="body2"
								sx={{
									fontWeight: 200,
									textAlign: "center",
									// textTransform: "capitalize",
								}}
								mx={2}
								mb={3}
							>
								Test your fashion sense with more curations
							</Typography>
							{playoffs.length > 0
								? playoffs.slice(1).map((playoff, i) => (
										<Box
											className={`playoffCard${i}`}
											key={`playoff-${i}`}
										>
											<PlayOffCard
												key={`playoff-${i}`}
												playoff={playoff}
												challengeSection={true}
											/>
										</Box>
								  ))
								: Array.from(new Array(4)).map(
										(item, index) => {
											return (
												<Box
													key={"knff" + index}
													sx={{
														marginRight: 0.5,
														my: 5,
													}}
												>
													<ActivePlayoffSkeleton
														challengeSection={true}
													></ActivePlayoffSkeleton>
												</Box>
											);
										}
								  )}

							<Box
								sx={{
									typography: "altag",
									display: "flex",
									alignItems: "center",
									flexWrap: "wrap",
									justifyContent: "center",
								}}
							>
								<Typography
									variant="atag"
									underline="none"
									component={Link}
									href="/playoff"
									direction="row"
									alignItems="center"
									color="primary"
								>
									More Playoffs{" "}
									<ArrowForwardIos
										fontSize="inherit"
										// sx={{ marginRight: 1 }}
									/>
								</Typography>
							</Box>
						</Box>
						<Divider variant="middle" />
						<Box
							ref={ref}
							sx={{
								marginY: 5,
								marginX: 2.5,
							}}
						>
							<Typography
								variant="h2"
								sx={{
									textAlign: "center",
									textTransform: "capitalize",
								}}
								pt={2}
								mb={3}
							>
								Curations by Category
							</Typography>
							{categories.length ? (
								<CategoryList
									categoryList={categories}
									inView={inView}
								/>
							) : (
								<List
									direction="row"
									component={Stack}
									spacing={2}
									sx={{
										display: "-webkit-box",
										overflowX: "scroll",
										msOverflowStyle: "none",
										scrollbarWidth: "none",
										"&::-webkit-scrollbar": {
											width: 0,
										},
									}}
								>
									{Array.from(new Array(4)).map(
										(item, index) => {
											return (
												<Box key={`cbc+${index}`}>
													<Skeleton
														key={`playoffloader-${index}`}
														animation="wave"
														variant="circular"
														width={98}
														height={98}
													/>{" "}
													<Skeleton />
												</Box>
											);
										}
									)}
								</List>
							)}
						</Box>
						<Box
							sx={{
								marginY: 5,
								marginX: 2.5,
							}}
						>
							<Typography
								variant="h2"
								sx={{
									textAlign: "center",
									textTransform: "capitalize",
								}}
								pt={2}
								mb={3}
							>
								Curations by PlayOff
							</Typography>
							{curated_playoffs.length > 0 ? (
								<PlayoffList playoffList={curated_playoffs} />
							) : (
								<List
									direction="row"
									component={Stack}
									spacing={2}
									sx={{
										display: "-webkit-box",
										overflowX: "scroll",
										msOverflowStyle: "none",
										scrollbarWidth: "none",
										"&::-webkit-scrollbar": {
											width: 0,
										},
									}}
								>
									{Array.from(new Array(4)).map(
										(item, index) => {
											return (
												<Box key={`cbp+${index}`}>
													<Skeleton
														key={`playoffloader-${index}`}
														animation="wave"
														variant="circular"
														width={98}
														height={98}
													/>{" "}
													<Skeleton />
												</Box>
											);
										}
									)}
								</List>
							)}
						</Box>

						<Box
							sx={{
								marginY: 5,
								marginX: 2.5,
							}}
						>
							<Typography
								variant="h2"
								sx={{
									textAlign: "center",
									textTransform: "capitalize",
								}}
								pt={2}
								mb={1}
							>
								Browse & Shop
							</Typography>
							<Typography
								variant="body2"
								sx={{
									fontWeight: 200,
									textAlign: "center",
									// textTransform: "capitalize",
								}}
								mx={2}
								mb={3}
							>
								Streetwear, glam partywear or workwear, we’ve
								got you covered!
							</Typography>
							<ScrollContainer
								items={curations}
								emptyText={"No Curations"}
								setItems={setCurations}
								APICallFunc={getCurations}
								page={pageDetail ? pageDetail.page : 2}
								session={session}
							>
								{curations.map((curation, i) => (
									<FeaturedCurationCard
										curations={curations}
										home={true}
										handleListFavorite={
											handleCurationListFavorite
										}
										key={`curations-${i}`}
										curation={curation}
										scrollContainerRef={scrollContainerRef}
									/>
								))}
							</ScrollContainer>
						</Box>
						<CompanyNameFooter required={true} />
						<BottomNavbar />
					</Box>

					<Tour
						steps={steps}
						isOpen={showProductTour}
						onRequestClose={closeProductTour}
						rounded={8}
						className="productTour"
						showNumber={false}
						nextButton={<div>NEXT</div>}
						prevButton={<div>PREV</div>}
						disableInteraction={true}
						lastStepNextButton={"FINISH"}
						disableFocusLock={true}
						maskSpace={3}
						accentColor={"#FFF"}
						closeWithMask={false}
						showNavigationNumber={false}
					/>
					<CurationDrawer
						setAuthOpen={setAuthOpen}
						session={session}
						status={status}
						setOpen={setOpen}
						open={open}
						otherCurations={[]}
						setCurationsCount={setCurationsCount}
						curationsCount={curationsCount}
					/>
					<AuthDrawer
						setOpen={setAuthOpen}
						open={authOpen}
					></AuthDrawer>
				</Box>
			)}
		</>
	);
};

export default Home;

export const getServerSideProps = async ({ req }) => {
	const token = await getAuthToken(req);

	// const { data } = await getDetails(token);
	// if (!data) {
	// 	return {
	// 		notFound: true,
	// 	};
	// }

	// const {
	// 	active_playoff: active_playoff1,
	// 	featured_curations: featuredCurationData,
	// 	// categories,
	// 	// grapeList: grapeListData,
	// 	// curated_playoffs,
	// } = data;
	return {
		props: {
			// active_playoff1,
			// featuredCurationData: [],
			token,
		},
	};
};
